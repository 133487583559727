<template id="responsive-map">
	<two-cards>
		<template #header>
			<h4 class="card-title">Filtros</h4>
		</template>
		<template #one>
			<form @submit.prevent="search()">
				<div class="form-row">
					<div class="form-group col-sm-3">
						<label for="i_nome">Área *</label>
						<vue-multi-select
							class="multi-100"
							searchPlaceholder="Pesquisar"
							v-model="areaSelecionada"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="areasOpcoes"
							:filters="multiSelectFilters[0]"
							@input="filtraMaquinas"
							:btnLabel="() => formBtnLabel(areaSelecionada)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label for="i_nome">Máquinas</label>
						<vue-multi-select
							class="multi-100"
							:disabled="!areaSelecionada.length"
							search
							searchPlaceholder="Pesquisar"
							v-model="maquinaSelecionada"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="maquinasOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => formBtnLabel(maquinaSelecionada)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label for="i_nome">Checklist</label>
						<vue-multi-select
							class="multi-100"
							search
							searchPlaceholder="Pesquisar"
							v-model="checklistsSelecionados"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="checklistOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => formBtnLabel(checklistsSelecionados)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label for="i_nome">Criticidade</label>
						<vue-multi-select
							class="multi-100"
							search
							searchPlaceholder="Pesquisar"
							v-model="criticidadeSelecionada"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="criticidadeOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => formBtnLabel(criticidadeSelecionada)"
						/>
					</div>
				</div>

				<div class="form-row">
					<div class="form-group col-sm-3">
						<label for="i_nome">Item</label>
						<vue-multi-select
							class="multi-100"
							search
							searchPlaceholder="Pesquisar"
							v-model="itensSelecionados"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="itensOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => formBtnLabel(itensSelecionados)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label for="i_nome">Confere</label>
						<HelpCircleIcon id="check" class="ml-2" size="15" />
						<b-popover target="check" triggers="hover" placement="top">
							<p>Filtra somente os itens de resposta "Sim" ou "Não".</p>
							<p><strong>SIM</strong> → Exibe apenas respostas que correspondem à referência.</p>
							<p><strong>NÃO</strong> → Exibe apenas respostas diferentes da referência.</p>
						</b-popover>
						<vue-multi-select
							class="multi-100"
							search
							searchPlaceholder="Pesquisar"
							v-model="confereSelecionados"
							:options="{ multi: true, labelName: 'nome' }"
							:selectOptions="confereOpcoes"
							:filters="multiSelectFilters[0]"
							:btnLabel="() => formBtnLabel(confereSelecionados)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label for="data-inicial">Data inicial *</label>
						<div class="d-flex">
							<datepicker
								input="date"
								placeholder="Inicio"
								id="data-inicial"
								input-class="bg-white"
								v-model="filtros.inicio"
								@selected="filtroData(filtros)"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								:state="validaData"
								data-cy="Data Inicial"
								class="w-50"
							></datepicker>
							<b-form-timepicker
								v-model="filtros.startTimepicker"
								class="w-50"
								locale="pt-BR">
							</b-form-timepicker>
						</div>
					</div>
					<div class="form-group col-sm-3">
						<label for="data-final">Data final *</label>
						<div class="d-flex">
							<datepicker
								placeholder="Fim"
								id="data-final"
								input-class="bg-white"
								v-model="filtros.fim"
								@selected="filtroData(filtros)"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Data Final"
								class="w-50"
							></datepicker>
							<b-form-timepicker
								v-model="filtros.endTimepicker"
								class="w-50"
								locale="pt-BR">
							</b-form-timepicker>
						</div>
					</div>
				</div>

				<p class="small text-right mt-2 mb-0">
					Campos marcados com * são obrigatórios.
				</p>

				<div class="d-flex justify-content-end tcs-card-footer-action">
					<b-dropdown
						id="dropdown-exportar"
						text="Exportar"
						variant="primary"
						class="mr-3"
						:disabled="dataLoading || flagExportando || relatorio.length <= 0 || !pesquisaRealizada"
						>
						<template #button-content>
							<span
							v-if="flagExportando"
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true">
							</span>
							<DownloadIcon v-if="!flagExportando" />
							Exportar
						</template>

						<b-dropdown-item
							@click="exportaRelatorioXlsx()"
							:disabled="flagExportando"
						>
							Excel
						</b-dropdown-item>
						<b-dropdown-item
							@click="exportaRelatorioPdf()"
							:disabled="flagExportando"
						>
							PDF
						</b-dropdown-item>
					</b-dropdown>

					<button
						@click ="search()"
						class="btn btn-success tcs-btn"
						type="submit"
						data-cy="Pesquisar"
						:disabled="dataLoading"
					>
						<SearchIcon />
					</button>
				</div>
			</form>
		</template>
		<template #two>
			<tbody v-if="dataLoading">
				<tb-skeleton shape="rect" theme="opacity"></tb-skeleton>
			</tbody>

			<tbody v-else-if="relatorio.length > 0 && pesquisaRealizada">
				<DataTable
					:fields="fields"
					:linhas="linhas"
					:totalRows="totalRows"
					:currentPage="currentPage"
					:itemsPerPage="itemsPerPage"
					:currentSort="currentSort"
					@change-page="(page) => search(page, true)"
					@items-per-Page="(value) => changeItemsPerPage(value)"
					@change-order ="(value) => order(value)"
				>
				</DataTable>
			</tbody>

			<template v-else>
				<b-alert show variant="warning" class="text-center my-auto" v-if="relatorio.length == 0 && pesquisaRealizada">
					Nenhum resultado encontrado para o período pesquisado.
				</b-alert>
			</template>
		</template>
	</two-cards>
</template>

<script>
	import { ptBR } from "vuejs-datepicker/dist/locale";
	import Datepicker from "vuejs-datepicker";
	import "vue2-google-maps";
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import { saveAs } from "file-saver";

	import TwoCards from "@/templates/TwoCards.vue";

	import { AreasService } from "../services/areas";
	import { ChecklistService } from "../services/checklists";
	import { MaquinasService } from "../services/maquinas";
	import { PreferencesService } from "@/services/preferencias";
	import DataTable from "@/components/DataTable";

	export default {
		components: {
			TwoCards,
			VueMultiSelect,
			Datepicker,
			DataTable
		},

		data () {
			return {
				flagExportando:false,
				areasOpcoes: [],
				areaSelecionada: [],
				maquinas:[],
				maquinaSelecionada: [],
				maquinasOpcoes: [],
				checklistsSelecionados: [],
				checklistOpcoes: [],
				criticidadeSelecionada: [],
				criticidadeOpcoes: [],
				itensSelecionados: [],
				itensOpcoes: [],
				confereSelecionados: [],
				confereOpcoes: [
					{ valor: true, nome: "SIM" },
					{ valor: false, nome: "NÃO" }
				],
				ptBR,
				preferences: [],
				filtros: {
					inicio: dayjs().format("YYYY-MM-DD"),
					fim: dayjs().format("YYYY-MM-DD"),
					startTimepicker: "00:00",
					endTimepicker: "23:59"
				},
				dataLoading: false,
				pesquisaRealizada: false,
				relatorio: [],
				totalRows: 0,
				currentPage: 1,
				currentSort: {
					by: 0,
					reverse: false
				},

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters()
				],
				fields: [
					{ key: "data", label: "Data", customSortable: true, orderKey: 0 },
					{ key: "area", label: "Área", customSortable: true, orderKey: 6 },
					{ key: "maquina", label: "Máquina", customSortable: true, orderKey: 5 },
					{ key: "checklist", label: "Checklist", customSortable: true, orderKey: 1 },
					{ key: "resposta", label: "Resposta do Operador", customSortable: true, orderKey: 2 },
					{ key: "confere", label: "Conforme esperado?", customSortable: true, orderKey: 8 },
					{ key: "checagem", label: "Checagem", customSortable: true, orderKey: 3 },
					{ key: "icon", label: "Criticidade", customSortable: true, orderKey: 4 },
					{ key: "observacao", label: "Observação", customSortable: true, orderKey: 7 }
				],
				itemsPerPage: 50,

				areasService: new AreasService(),
				checklistService: new ChecklistService(),
				maquinasService: new MaquinasService(),
				preferenceService: new PreferencesService()
			};
		},

		computed: {
			validaData () {
				return dayjs(dayjs(this.filtros.inicio).format("YYYY-MM-DD")).isBefore(dayjs(this.filtros.fim).format("YYYY-MM-DD"));
			},

			calculaDatas () {
				const dataInicio = dayjs(this.filtros.inicio).format("YYYY-MM-DD");
				const dataFinal = dayjs(this.filtros.fim).format("YYYY-MM-DD");

				return dayjs(dataFinal).diff(dataInicio, "days") + 1;
			},

			linhas () {
				return this.relatorio.map(row => {
					const machine = row.evento_checklist.maquina;
					const area = this.areasOpcoes.find(a => a.id === machine.id_area);
					const matchesExpected = row.resposta_boolean === row.item_checklist.referencia;
					const line = {
						id: row.id,
						data: dayjs(row.evento_checklist.data).format("DD/MM/YYYY HH:mm:ss"),
						area: (area || { nome: "-" }).nome,
						maquina: (machine || { nome: "-" }).nome,
						confere: "-",
						checklist: row.evento_checklist.checklist.nome,
						checagem: row.item_checklist.nome,
						icon: {
							text: row.item_checklist.criticidade,
							icon: row.item_checklist.criticidade === ChecklistService.Criticidades.ImmediateStop
								? "BanIcon"
								: "AlertTriangleIcon"
						},
						observacao: matchesExpected || (row.resposta_boolean !== true && row.resposta_boolean !== false) ? null : row.evento_checklist.observacao
					};

					if (row.resposta_string !== null && row.resposta_string !== undefined) {
						line.resposta = row.resposta_string;
					}
					else if (row.resposta_number !== null && row.resposta_number !== undefined) {
						line.resposta = row.resposta_number;
					} else {
						line.resposta = row.resposta_boolean ? "SIM" : "NÃO";
						line.confere = {
							color: matchesExpected ? "green" : "red",
							icon: matchesExpected
								? "CheckIcon"
								: "XIcon"
						};
					}

					return line;
				});
			}
		},

		async mounted () {
			this.maquinas = (await this.maquinasService.listMachines() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.areasOpcoes = (await this.areasService.listAreas() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.checklistOpcoes = (await this.checklistService.listChecklists() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.itensOpcoes = (await this.checklistService.listItensChecklist() || []).map(m => ({
				nome: m.nome,
				...m
			}));
			this.criticidadeOpcoes = this.checklistService.listCriticidades();
			this.preferences = await this.preferenceService.getPreferences(["AREA_MAPAS"]);
			const idArea = Number((this.preferences.find(p => p.nome === "AREA_MAPAS") || {}).valor);
			this.areaSelecionada[0] = this.areasOpcoes.find(p => p.id === idArea) || [];

			this.filtraMaquinas();
			this.filtroData(this.filtros);
		},

		methods: {
			formBtnLabel (itens) {
				if (!itens.length)
					return "Selecione";
				else
					return itens.length > 1 ? `${itens.length} itens selecionados` : itens[0].nome;
			},

			filtraMaquinas () {
				this.maquinasSelecionadas = [];
				if (this.areaSelecionada[0])
					this.maquinasOpcoes = this.maquinas.filter(m => m.id_area == this.areaSelecionada[0].id);
			},

			formatter (date) {
				return dayjs(date).format("DD/MM/YYYY");
			},

			filtroData (fr) {
				if (fr.inicio)
					this.filtros.inicio = dayjs(fr.inicio).toDate();

				if (fr.fim)
					this.filtros.fim = dayjs(fr.fim).toDate();
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			changeItemsPerPage (value) {
				this.itemsPerPage = value;
				this.search(1);
			},

			order (value) {
				this.currentSort = value;
				this.search(this.currentPage);
			},

			getFilterForSearch () {
				let dataInicial = dayjs(this.filtros.inicio);
				let dataFinal = dayjs(this.filtros.fim);
				dataInicial = dataInicial.set("hour", parseInt(this.filtros.startTimepicker.split(":")[0]))
					.set("minute", parseInt(this.filtros.startTimepicker.split(":")[1]))
					.set("second", 0)
					.set("millisecond", 0);

				dataFinal = dataFinal.set("hour", parseInt(this.filtros.endTimepicker.split(":")[0]))
					.set("minute", parseInt(this.filtros.endTimepicker.split(":")[1]))
					.set("second", 0)
					.set("millisecond", 0);

				const mapSelected = (elementSelect, elementOption, criticality = false) => {
					if (elementSelect.length)
						return elementSelect.map(e => criticality ? e.nome : e.id);
					else
						return elementOption.map(e => criticality ? e.nome : e.id);
				};

				const filter = {
					maquinas: mapSelected(this.maquinaSelecionada, this.maquinasOpcoes),
					checklists: mapSelected(this.checklistsSelecionados, this.checklistOpcoes),
					itens: mapSelected(this.itensSelecionados, this.itensOpcoes),
					dataInicial,
					dataFinal,
					criticidade: mapSelected(this.criticidadeSelecionada, this.criticidadeOpcoes, true)
				};

				if (this.confereSelecionados.length === 1) {
					if (this.confereSelecionados[0].valor === true)
						filter.confere = true;
					else
						filter.naoConfere = true;
				}

				return filter;
			},

			async search (pagina = 1) {
				pagina = pagina || 1;
				this.currentPage = pagina;

				try {
					const filter = this.getFilterForSearch();
					const dataInvalida = dayjs(filter.dataFinal).isBefore(filter.dataInicial);
					if (dataInvalida) {
						this.$swal({
							type: "warning",
							title: "Selecione uma data válida!",
							confirmButtonText: "Fechar",
							confirmButtonColor: "#6c757d"
						});
						return;
					}

					this.dataLoading = true;
					this.ready = false;
					this.pesquisaRealizada = false;
					const offset = (pagina - 1) * this.itemsPerPage;

					const result = await this.checklistService.getRelatorio({
						filter,
						limit: this.itemsPerPage,
						offset,
						ordering: this.currentSort
					}) || [];

					this.relatorio = result.data;
					this.totalRows = result.totalRows;

					this.pesquisaRealizada = true;
					this.dataLoading = false;
				} catch (err) {
					console.error(err);
					this.dataLoading = false;
					this.ready = false;

					this.$swal({
						type: "error",
						title: "Falha ao fazer a busca!",
						html: `<p>${err.response ? err.response.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			async exportaRelatorioXlsx () {
				try {
					const filter = this.getFilterForSearch();
					this.flagExportando = true;
					const relatorio = await this.checklistService.geraPlanilhaXlsx({
						filter
					}) || [];

					if (relatorio && !relatorio.message) {
						const blob = new Blob([relatorio.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
						saveAs(blob, "Relatorio-de-checklist" + ".xlsx");
						this.flagExportando = false;
					} else {
						this.flagExportando = false;
						this.$swal({
							title: relatorio.message,
							type: relatorio.type || "warning",
							confirmButtonText: "Fechar",
							confirmButtonColor: "#6c757d"
						});
					}
				} catch (err) {
					console.error(err);

					this.$swal({
						type: "error",
						title: "Falha ao exportar o Excel. Tente novamente mais tarde!",
						html: `<p>${err.response ? err.response.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			async exportaRelatorioPdf () {
				try {
					const filter = this.getFilterForSearch();
					this.flagExportando = true;
					const relatorio = await this.checklistService.geraRelatorioPdf({
						filter
					}) || [];

					if (relatorio && !relatorio.message) {
						const blob = new Blob([relatorio.data], { type: "application/pdf" });
						saveAs(blob, "Relatorio-de-checklist" + ".pdf");
						this.flagExportando = false;
					} else {
						this.flagExportando = false;
						this.$swal({
							title: relatorio.message,
							type: relatorio.type || "warning",
							confirmButtonText: "Fechar",
							confirmButtonColor: "#6c757d"
						});
					}
				} catch (err) {
					console.error(err);

					this.$swal({
						type: "error",
						title: "Falha ao exportar o Excel. Tente novamente mais tarde!",
						html: `<p>${err.response ? err.response.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			}
		}
	};
</script>

<style>
	@media (max-width: 790px) {
		main .card:first-child {
			border: 0;
			min-height: auto !important;
		}
	}

	.btn-primary {
		color: #fff;
		background-color: #6DCFF6;
		border-color: #6DCFF6;
		border-radius: 10px 10px 0px 0;
	}

	.btn-primary:hover {
		color: #fff;
		background-color: #59b0d3;
		border-color: #59b0d3;
	}

	.btn-primary:disabled {
		color: #fff;
		background-color: #59b0d3;
		border-color: #59b0d3;
	}

	.tb-skeleton {
		background-color: #dcdcdc;
		height: 25rem;
		width: 100%;
	}
</style>
